// import { propTypes } from "gatsby-plugin-image/dist/src/components/gatsby-image.server"
import PropTypes from "prop-types"
import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

import "../styles/global.scss"
import Seo from "../components/seo/seo.js"
import Nav from "../components/nav/nav.js"
import Footer from "../components/footer/footer.js"


// import 'uikit/dist/css/uikit.css'
import UIkit from 'uikit'


const Layout = ({ children, pageData, SEOTitle, SEODescription, footerDisclaimer, darkThemeNav, meta }) => {



    return (
        <>
            {/* SEO section */}
            <Seo title={SEOTitle} description={SEODescription} meta={meta}></Seo>
            <Nav forcedDarkTheme={darkThemeNav}></Nav>

            <main>{children}</main>

            {/* footer section */}
            <Footer disclaimer={footerDisclaimer}></Footer>
            <CookieConsent
                // debug="true"
                location="bottom"
                cookieName="wayfarersPrivacy"
                style={{ background: "#EBDA80", color: "#232826" }}
                buttonStyle={{ background: "#232826", color: "#fff" }}
                expires={150}


            >We are committed to preserving the privacy of all users of this website. <Link to="/privacy-policy" className="privacy-more" style={{ color: "rgba(0, 0, 0, 0.6)", textDecoration: "underline" }}>Find out more</Link></CookieConsent>
        </>
    )

}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
