import React, { useState, useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
//import 'uikit/dist/css/uikit.css'
import { BsFillTelephoneFill, BsInstagram, BsTwitterX } from "react-icons/bs"
import { FaFacebookF } from 'react-icons/fa'

import "./nav.scss"



import { GatsbyImage } from "gatsby-plugin-image"
import { FaPhone } from "react-icons/fa"
import CustomMarkdown from "../../utils/customMarkdown"
import megamenuImage from '../../assets/megamenuCTA.png';


const Nav = ({ forcedDarkTheme }) => {

    const data = useStaticQuery(graphql`
        {
            strapiMenu{
                MenuList{
                    Title
                    URL
                }

            }
            allStrapiShops(sort: {fields: Title, order: ASC}) {
                nodes {
                    Title
                    Slug
                    shop_category {
                        Slug
                      }
                  }
              }
            strapiSiteSettings{
                SocialLinks {
                    FacebookURL
                    Instagram
                    LinkedinURL
                    PhoneNumber
                    TwitterURL
                }
                LogoDark{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
                LogoLight{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: NONE
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }

            }
        }
    
    `)


    const [nextLogo, setNextLogo] = useState(false);

    useEffect(() => {
        const timeout = setInterval (() => {
            setNextLogo(!nextLogo);
        }, 4000);

        return () => clearInterval(timeout)
    }, [nextLogo])

    return (
        <div className="sticky-nav">
            <nav className="uk-navbar-container" uk-navbar="true">
                <div className="uk-navbar-left navbar-left" id="left-navbar">
                    <ul className="uk-navbar-nav">
                        <li>
                            {data.strapiSiteSettings.LogoLight.localFile.childImageSharp && data.strapiSiteSettings.LogoDark.localFile.childImageSharp ?
                                <div className={nextLogo ? "other" : "bar"}>
                                    <Link to="/">
                                        <GatsbyImage className="logo logo1" image={data.strapiSiteSettings.LogoLight.localFile.childImageSharp.gatsbyImageData} alt="The insurers logo light" width="200" height="39"></GatsbyImage>
                                        <GatsbyImage className="logo logo2" image={data.strapiSiteSettings.LogoDark.localFile.childImageSharp.gatsbyImageData} alt="The insurers logo light" width="200" height="39"></GatsbyImage>
                                    </Link>
                                </div>
                                :
                                <Link to="/"><img src={data.strapiSiteSettings.LogoLight.url} alt="The insurers logo light" width="200" height="39" /></Link>
                            }

                        </li>
                    </ul>
                </div>

                <div className="uk-navbar-right uk-visible@l">
                    <div>
                        <ul className="uk-navbar-nav social-links">
                            {
                                data.strapiSiteSettings.SocialLinks.PhoneNumber ? <li><BsFillTelephoneFill /><span className="social-phone"><a href={`tel:+${data.strapiSiteSettings.SocialLinks.PhoneNumber}`}>{data.strapiSiteSettings.SocialLinks.PhoneNumber}</a></span></li> : ""

                            }
                            {
                                data.strapiSiteSettings.SocialLinks.FacebookURL ? <li><span><a href={data.strapiSiteSettings.SocialLinks.FacebookURL}><FaFacebookF /></a></span></li> : ""
                            }
                            {
                                data.strapiSiteSettings.SocialLinks.TwitterURL ? <li><span><a href={data.strapiSiteSettings.SocialLinks.TwitterURL}><BsTwitterX /></a></span></li> : ""

                            }
                            {
                                data.strapiSiteSettings.SocialLinks.Instagram ? <li><span><a href={data.strapiSiteSettings.SocialLinks.Instagram}><BsInstagram /></a></span></li> : ""
                            }

                        </ul>
                        <ul className="uk-navbar-nav ul-navbar-right">
                            {
                                data.strapiMenu.MenuList ? data.strapiMenu.MenuList.map((item, index) => {

                                    return (

                                        <li key={index}><Link to={`/${item.URL}`}>{item.Title}</Link>
                                            {
                                                item.Title == 'Shops' ?
                                                    <div className="uk-navbar-dropdown submenu-container" data-uk-dropdown="offset: 10">
                                                        <div className="submenu-container__display-flex">
                                                            <div className="first-column">
                                                                <div className="uk-grid" uk-grid="true">
                                                                    {
                                                                        data.allStrapiShops.nodes ? data.allStrapiShops.nodes.filter((item, index) => {

                                                                            return item.shop_category?.Slug !== 'to-let';

                                                                        }).map((item, index) => {

                                                                            return (

                                                                                <div className="uk-width-1-3 menu-item" key={index}>
                                                                                    <Link to={`/shop/${item.Slug}`}>{item.Title}</Link>
                                                                                </div>
                                                                            )
                                                                        }) : ""
                                                                    }
                                                                    <div className="uk-width-1-3 menu-item" key={index}>
                                                                        <Link to={`/shop/?type=to-let`}>Shop To Let</Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="fourth-column">
                                                                <ul className="uk-nav uk-navbar-dropdown-nav submenu-list">
                                                                    <img src={megamenuImage}></img>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ""
                                            }
                                        </li>
                                    )
                                }) : ""
                            }
                        </ul>
                    </div>
                </div>

                <div className="uk-navbar-right uk-hidden@l">
                    <a href="#offcanvas-usage" className="uk-navbar-toggle menuMobileBtn" data-uk-toggle="target: #offcanvas-menu">
                        <span className="uk-margin-small-right">Menu</span>
                        <span data-uk-navbar-toggle-icon>&nbsp;</span>
                    </a>
                </div>

                <div id="offcanvas-menu" data-uk-offcanvas="overlay: true; flip: true; mode: reveal">
                    <div className="uk-offcanvas-bar uk-flex uk-flex-column">

                        <button className="uk-offcanvas-close" type="button" data-uk-close aria-label="close button"></button>

                        <ul className="uk-nav uk-nav-primary uk-nav-center uk-margin-auto-vertical uk-nav-parent-icon" data-uk-nav="multiple: true">

                            {
                                data.strapiMenu.MenuList ? data.strapiMenu.MenuList.map((item, index) => {

                                    return (

                                        <li key={index}>
                                            <Link to={`/${item.URL}`} className="canvas-link">
                                                {item.Title}
                                            </Link>
                                        </li>

                                    )
                                }) : ""
                            }
                        </ul>
                    </div>
                </div>

            </nav >
        </div >

    )
}

export default Nav