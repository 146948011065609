import React from "react"
import { useStaticQuery, graphql } from "gatsby"

// import Logo from "../../assets/logo-light@2x.png"
// import LogoDark from "../../assets/logo-dark@2x.png"
// import 'uikit/dist/css/uikit.css'

import "./footer.scss"

import CustomMarkdown from "../../utils/customMarkdown"
import { BsFillTelephoneFill } from "react-icons/bs"
import { MdMail, MdLocationOn } from 'react-icons/md';

const Footer = ({ disclaimer }) => {

    const data = useStaticQuery(graphql`
        {
            strapiFooter {
                Email
                Copyright
                Disclaimer
                OpeningHours {
                    Day
                    Hours
                  }
                LinkList {
                    Title
                    URL
                  }
            }

            strapiSiteSettings{
                LogoLight{
                    url
                    localFile{
                        childImageSharp{
                            gatsbyImageData(
                                width: 180
                                placeholder: BLURRED
                                formats: [AUTO, WEBP]
                            )
                        }
                    }
                }
            }
        }
    `)
    return (
        <>
            <section className="footer-section">
                <div className="footer-wrapper">

                    <div className="footer-grid uk-grid" data-uk-grid>
                        <div className="uk-width-1-3@m uk-width-1-2@s footer-column  uk-text-left">
                            <h3>Contact US</h3>
                            <ul>
                                <li>
                                    <div className="footer-column__location">
                                        <div className="icon-location">
                                            <MdLocationOn />
                                        </div>
                                        <div className="footer-column__location-content">
                                            <p>Wayfares Shopping Arcade <br />Lord Street <br />Southport <br /> PR8 1NT</p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-column__location">
                                        <div className="icon-location">
                                            <BsFillTelephoneFill />
                                        </div>
                                        <div className="footer-column__location-content">
                                            <p>Management Office <br /><a href="tel:+01704539077">01704 539077</a> <br />To find individual shops telephone<br /> numbers, <a href="/shop" className="footer-click-link">check our shop page</a></p>
                                        </div>
                                    </div>
                                </li>
                                <li>
                                    <div className="footer-column__location">
                                        <div className="icon-location">
                                            <MdMail />
                                        </div>
                                        <div className="footer-column__location-content">
                                            <p className="footer-email"><a href={`mailto:${data.strapiFooter.Email}`}>{data.strapiFooter.Email}</a></p>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                        <div className="uk-width-1-3@m uk-width-1-2@s second-footer-column footer-column uk-text-left">
                            <h3>Opening Hours</h3>
                            <ul>
                                {
                                    data.strapiFooter.OpeningHours.map((item, index) => {

                                        return (
                                            <li key={index}>
                                                <div className="footer-column-hours">
                                                    <p>{item.Day}</p>
                                                    <p className="hours">{item.Hours}</p>
                                                </div>
                                            </li>


                                        )
                                    })
                                }
                            </ul>
                        </div>
                        <div className="uk-width-1-3@m uk-width-1-2@s footer-column third-footer-column uk-text-left" >
                            <h3>Links</h3>
                            <ul>
                                {
                                    data.strapiFooter.LinkList.map((item, index) => {

                                        return (
                                            
                                            <li key={index}><a href={item.URL}>{item.Title}</a></li>
                                            
                                        )
                                    })
                                }
                                <li>
                                    <p className="copyright">Copyright © {new Date().getFullYear()}.</p>
                                    <CustomMarkdown children={data.strapiFooter.Copyright}></CustomMarkdown>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="footer__copyright">
                        {/* <p>{data.strapiFooter.Disclaimer}</p>
                        {disclaimer ? <CustomMarkdown children={disclaimer}></CustomMarkdown> : ""} */}

                        {/* {footerData !== undefined && (footerData.slug === "cheapest-price-guaranteed" || footerData.slug === "compare-motorhome-insurance") ? <p>{cheapestExtraText}</p>: ""} */}

                        {/* <p>Copyright © Vast Visibility Ltd {new Date().getFullYear()}. All Rights Reserved</p> */}
                    </div>

                    <div>
                        <p className="no-margin-bottom">{data.strapiFooter.Disclaimer}</p>
                        
                    </div>

                </div>
            </section>
        </>
    )
}

export default Footer